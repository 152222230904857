import React from 'react'

// Images
import SSBZzpIcon from 'img/SSB_zzp_icon.svg'
import SSBArrowRight from 'img/SSB_arrow_right.svg'
import SSBLoonIcon from 'img/SSB_loondienst_icon.svg'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'

const StyledZzpLoon = styled.div``

const TitleContainer = styled.div`
  ${props => props.right && css`
    padding-left: 120px;

    @media screen and (max-width: 991px) {
      padding-left: unset;
    }
  `}
`

const ContentContainer = styled.div`
  ${props => props.left && css`
    padding-right: 120px;

    @media screen and (max-width: 991px) {
      padding-right: unset;
    }
  `}

  ${props => props.right && css`
    padding-left: 120px;

    @media screen and (max-width: 991px) {
      padding-left: unset;
    }
  `}

  p, ul {
    font-size: ${props => props.theme.font.size.m} !important;
    font-weight: ${props => props.theme.font.weight.s};

    ${props => props.left && css`
      color: ${props.theme.color.text.light};
    `}

    ${props => props.right && css`
      color: ${props.theme.color.text.secondary};
    `}
  }

  a, button {
    padding: 0;
    color: ${props => props.theme.color.text.light};
    text-decoration: underline;
  }

  ul {
    padding-left: 1.2rem;
  }

  h2 {
    font-size: ${props => props.theme.font.size.xm} !important;
    margin-top: 40px;
    margin-bottom: 10px;

    ${props => props.left && css`
      color: ${props.theme.color.text.light};
    `}

    ${props => props.right && css`
      color: ${props.theme.color.text.secondary};
    `}
  }

  strong {
    font-weight: ${props => props.theme.font.weight.xl};
    padding-bottom: 0;
    margin-bottom: 0;
  }
`

const ZZP = styled.div`
  background-color: ${props => props.theme.color.light_gray};
  position: relative;

  h2 {
    font-size: ${props => props.theme.font.size.xl};
  }

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 2000px;
    right: -2000px;
    background-color: ${props => props.theme.color.light_gray};
  }

  @media screen and (max-width: 991px) {
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      top: 0;
      width: 2000px;
      left: -2000px;
      background-color: ${props => props.theme.color.light_gray};
    }
  }
`

const Loon = styled.div`
  background-color: ${props => props.theme.color.main};

  h2 {
    font-size: ${props => props.theme.font.size.xl};
  }

  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 2000px;
    left: -2000px;
    background-color: ${props => props.theme.color.main};
  }

  @media screen and (max-width: 991px) {
    &::after {
      content: "";
      position: absolute;
      height: 100%;
      top: 0;
      width: 2000px;
      right: -2000px;
      background-color: ${props => props.theme.color.main};
    }
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  position: relative;
  bottom: -18px;
  z-index: 77;

  p {
    white-space: nowrap;
  }
`

const ZzpLoon = ({ className, zzp, loon }) => {
  const goToVacancies = () => {
    if (typeof document !== "undefined") {
      const element = document.getElementById('#vacatures')
      element.scrollIntoView({behavior: 'smooth'})
    }
  }

  return (
    <StyledZzpLoon className={`${className ? `${className}` : ``}`}>
      <div className="container">
        <div className="row">
        <Loon className="col-lg-6">
            <TitleContainer left className="d-flex pb-4 pt-5 align-items-center">
              <img className="mr-4" src={SSBLoonIcon} alt="" />
              <h2 className="mb-0 mt-3 font-weight-xl color-text-light">{loon.title}</h2>
            </TitleContainer>
            <ContentContainer left>
              {parse(loon.text, {
                replace: (domNode) => {
                  if (domNode.name === 'a') {
                    return <button type="button" onClick={() => goToVacancies()} to={domNode.attribs.href}>{domNode.children[0].data}</button>
                  }
                  return false
                }
              })}
            </ContentContainer>
          </Loon>
          <ZZP className="col-lg-6">
            <TitleContainer right className="d-flex pb-4 pt-5 align-items-center">
              <img className="mr-4" src={SSBZzpIcon} alt="" />
              <h2 className="mb-0 mt-3 font-weight-xl color-text-secondary">{zzp.title}</h2>
            </TitleContainer>
            <ContentContainer right>
              {parse(zzp.text)}
            </ContentContainer>
            <div className="d-flex justify-content-center">
              <StyledButtonDefault to={zzp.link.url} className="py-1">
                <p className="mb-0 px-3 font-weight-xl">{zzp.link.title}</p>
                <img src={SSBArrowRight} alt="" />
              </StyledButtonDefault>
            </div>
          </ZZP>
        </div>
      </div>
    </StyledZzpLoon>
  )
}

export default ZzpLoon