import React from 'react'

// Images
import SSBArrowRight from 'img/SSB_arrow_right.svg'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const StyledSingleVacature = styled.div`
  box-shadow: 0px 0px 30px -15px rgba(0,0,0,0.75);
  padding: 40px 80px;
  position: relative;
  margin-bottom: 100px;

  @media screen and (max-width: 768px) {
    padding: 40px 40px;
  }
`

const TitleContainer = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xm};
  }
`

const DescriptionContainer = styled.div`
  p {
    line-height: 30px;
    font-size: ${props => props.theme.font.size.m};
    color: ${props => props.theme.color.text.secondary};
    font-weight: ${props => props.theme.font.weight.s};
    margin-bottom: 0;
  }
`

const InfoContainer = styled.div`
  strong {
    color: ${props => props.theme.color.main};
  }
`

const StyledButtonDefault = styled(ButtonDefault)`
  position: absolute;
  bottom: -20px;
  right: 80px;

  @media screen and (max-width: 768px) {
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 220px;
  }

  p {
    white-space: nowrap;
    font-size: ${props => props.theme.font.size.m};
  }
`

const SingleVacature = ({ className, vacature }) => {
  return (
    <StyledSingleVacature className={`${className ? `${className}` : ``}`}>
      <TitleContainer className="mb-3">
        <h2 className="text-uppercase font-weight-xl color-text-secondary">{vacature.acf.info.title}</h2>
      </TitleContainer>
      <InfoContainer className="d-md-flex d-block">
        <p className="color-text-secondary mb-0 mr-md-3 d-md-block d-flex">
          <strong className="d-block d-md-none mr-2">-</strong>
          {vacature.acf.info.place}
        </p>
        <strong className="d-none d-md-block">|</strong>
        <p className="color-text-secondary mb-0 mx-md-3 d-md-block d-flex">
          <strong className="d-block d-md-none mr-2">-</strong>
          {`Salaris: ${vacature.acf.info.salary}`}
        </p>
        <strong className="d-none d-md-block">|</strong>
        <p className="color-text-secondary ml-md-3 d-md-block d-flex">
          <strong className="d-block d-md-none mr-2">-</strong>
          {vacature.acf.info.function}
        </p>
      </InfoContainer>
      <DescriptionContainer>
        {parse(vacature.acf.info.description)}
      </DescriptionContainer>
      <StyledButtonDefault className="py-1" to={vacature.path}>
        <p className="font-weight-xl color-text-light px-3 mb-0">Bekijk vacature</p>
        <img src={SSBArrowRight} alt="" />
      </StyledButtonDefault>
    </StyledSingleVacature>
  )
}

export default SingleVacature