import React from 'react'

// Third Party
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import SingleVacature from './SingleVacature'

const StyledVacatures = styled.div`
  margin-top: 100px;
  margin-bottom: 170px;
`

const TitleContainer = styled.div`
  h2 {
    font-size: ${props => props.theme.font.size.xxml};
  }
`

const Vacatures = ({ className }) => {
  const {
    vacatures: {
      edges: vacatures
    }
  } = useStaticQuery(graphql`{
    vacatures: allWordpressWpVacature {
      edges {
        node {
          ...VacatureFrag
        }
      }
    }
  }`)
      
  return (
    <StyledVacatures className={`${className ? `${className}` : ``}`}>
      <TitleContainer className="mb-5">
        <h2 className="text-center font-weight-xl">Vacatures</h2>
      </TitleContainer>
      <div id="#vacatures" />
      {vacatures.map(vacature => (
        <SingleVacature key={vacature.node.wordpress_id} vacature={vacature.node} />
      ))}
    </StyledVacatures>
  )
}

export default Vacatures