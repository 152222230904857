/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import ZzpLoon from 'components/workat/ZzpLoon'
import Vacatures from 'components/workat/Vacatures'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...WorkatFrag
    }
  }
`

const WorkatTemplate = ({
  data: {
    page: {
      path,
      acf: {
        banner,
        zzp,
        loondienst
      },
      yoastMeta
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />

      <Hero bigger content={banner} />
  
      <ZzpLoon className="mb-5" zzp={zzp} loon={loondienst} />

      <div className="container">
        <Vacatures />
      </div>

    </Layout>
  )
}

export default WorkatTemplate
